import axios from "axios"
import { TodoItem } from "../components/Todo"
import { TodoGroupItem } from "../components/TodoGroup"

const isDevEnv = false

const getAPI = () => {
  if (isDevEnv) {
    return "http://localhost:7071/api"
  } else {
    return "https://studuapi.azurewebsites.net/api"
  }
}

const getGroups = (todoArr: any[]) => {
  // console.log("Generating Group List");
  let tmpGroupArr: TodoGroupItem[] = []
  let tmpGroup: TodoGroupItem = { groupname: "", groupTodoList: [] }
  let tmpGroupName: string = ""
  todoArr.forEach((tele: any, tid: any, tarr: any) => {
    if (tmpGroupName === "") {
      tmpGroupName = tele.todogroup
      tmpGroup.groupname = tmpGroupName
      tmpGroup.groupTodoList.push({
        id: tele.id,
        todonum: tele.todonum,
        task: tele.task,
        isComplete: tele.isComplete,
        todogroup: tele.todogroup,
      })
    } else {
      if (tmpGroupName !== tele.todogroup) {
        tmpGroupArr.push(tmpGroup)
        tmpGroup = { groupname: "", groupTodoList: [] }
        tmpGroupName = tele.todogroup
        tmpGroup.groupname = tmpGroupName
        tmpGroup.groupTodoList.push({
          id: tele.id,
          todonum: tele.todonum,
          task: tele.task,
          isComplete: tele.isComplete,
          todogroup: tele.todogroup,
        })
        tmpGroupArr.push(tmpGroup)
      } else {
        tmpGroup.groupTodoList.push(tele)
      }
    }
  })
  // console.log("Group created : " + JSON.stringify(tmpGroupArr, null, 2));
  return tmpGroupArr
}

export const getTodos = async () => {
  //   console.log("invoking axios for update");
  // ------- Azure Function -------
  // const API = "https://studuapi.azurewebsites.net/api";
  if (!isDevEnv) {
    const KEY = "J34e18b53/a0lQhfyGMpw3xx68DkVZhe/4U9eVfqzcoJxJywYRaV4Q=="
    // axios defaults
    axios.defaults.headers.common["x-functions-key"] = KEY
  }
  // ------- Azure Function changes -------

  const todoArr: TodoItem[] = []
  //   console.log("about to call axios");
  await axios.get(`${getAPI()}/todos`).then(res => {
    //   console.log(JSON.stringify(res.data));
    const todolist = res.data
    // console.log("received data");
    todolist.sort((a: TodoItem, b: TodoItem) => {
      if (a.todogroup > b.todogroup) return 1
      if (a.todogroup < b.todogroup) return -1
      return 0
    })
    // console.log("GetTodos:" + JSON.stringify(todolist, null, 2));
    todolist.forEach(
      (ele: {
        id: any
        todonum: any
        task: any
        isComplete: any
        todogroup: any
      }) => {
        todoArr.push({
          id: ele.id,
          todonum: ele.todonum,
          task: ele.task,
          isComplete: ele.isComplete,
          todogroup: ele.todogroup,
        })
      }
    )
  })

  return getGroups(todoArr)
}

export const updateTodo = async (todo: TodoItem) => {
  // const API = "http://localhost:7071/api";
  // const KEY = "";
  //   console.log("invoking axios for update");
  // ------- Azure Function -------
  // const API = "https://studuapi.azurewebsites.net/api";
  if (!isDevEnv) {
    const KEY = "NKcgn11G4m7JyiC0MSB3TBCbvUpq2I5Va7HnbSO1gqiz57lmp8H/vw=="
    // axios defaults
    axios.defaults.headers.common["x-functions-key"] = KEY
  }
  // ------- Azure Function changes -------

  await axios
    .put(`${getAPI()}/todo`, todo)
    .then(() => {
      //   console.log("item updated");
      //   this.showSuccess("Item updated");
    })
    .catch(err => {
      console.log("error updating item", err.message)
    })
}

export const addTodo = async (todo: TodoItem) => {
  // const API = "https://mslearn-tailwind-api.azurewebsites.net/api";
  // const KEY = "agwZ3ER1C3j666Zp0jstzH8S2QZ82avEbKAweyl3rzcCule/CcZmdw==";
  // const API = "http://localhost:7071/api";
  // const KEY = "";
  //   console.log("invoking axios for update");
  // ------- Azure Function -------
  // const API = "https://studuapi.azurewebsites.net/api";
  if (!isDevEnv) {
    const KEY = "2QSWstvSGBypxHiOwDpCIqmaXdecbNqyRg8057ymXVBuAuMpVM0eZQ=="
    // axios defaults
    axios.defaults.headers.common["x-functions-key"] = KEY
  }
  // ------- Azure Function changes -------
  const newTodo: TodoItem = {
    id: "",
    todonum: "",
    task: "",
    isComplete: false,
    todogroup: "",
  }
  await axios
    .post(`${getAPI()}/todo`, todo)
    .then((item: any) => {
      // console.log("item added :" + JSON.stringify(item.data, null, 2));
      newTodo.id = item.data.id
      newTodo.todonum = item.data.todonum
      newTodo.task = item.data.task
      newTodo.isComplete = item.data.isComplete
      newTodo.todogroup = item.data.todogroup
    })
    .catch(err => {
      console.log("error inserting item", err.message)
    })
  return newTodo
}

export const deleteTodo = async (todo: TodoItem): Promise<boolean> => {
  // const API = "http://localhost:7071/api";
  // const KEY = "";
  //   console.log("invoking axios for update");
  // ------- Azure Function -------
  // const API = "https://studuapi.azurewebsites.net/api";
  if (!isDevEnv) {
    const KEY = "dtSUvkP20NAF9VJRwOfXAbyI1LukeTDURvmsyoHIH7k6GXHRK1JPbw=="
    // axios defaults
    axios.defaults.headers.common["x-functions-key"] = KEY
  }
  // ------- Azure Function changes -------

  //   const KEY = "";
  //   console.log("invoking axios for update");
  let retVal = false
  await axios
    .delete(`${getAPI()}/todo/${todo.id}`, {
      data: {
        todonum: todo.todonum,
      },
    })
    .then(() => {
      // use the index to remove from the products array
      // console.log("Delete API successful, returning true");
      retVal = true
      // this.showSuccess("Item deleted");
    })
    .catch(err => {
      console.log("error deleting item", err.message)
      retVal = false
    })
  // console.log("Delete API function end");
  return retVal
}
