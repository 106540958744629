import { Box, IconButton, Stack } from "@chakra-ui/core"
import { Editable, EditableInput, EditablePreview } from "@chakra-ui/core"
import React, { useEffect } from "react"
import { useState } from "react"
import { addTodo, deleteTodo } from "../util/TodoAPI"
import { Todo, TodoItem } from "./Todo"

export interface TodoGroupItem {
  groupname: string
  groupTodoList: TodoItem[]
}

interface TodoGroupProps {
  groupItem: TodoGroupItem
  groupKey: number
  onChangeGroup: any
}

export const TodoGroup: React.FC<TodoGroupProps> = ({
  groupItem,
  groupKey,
  onChangeGroup,
  ...props
}) => {
  //   let todoGArr: TodoGroupItem = { groupname: "", groupTodoList: [] };
  const tmpTodo: TodoItem = {
    id: "",
    todonum: "",
    task: "",
    isComplete: false,
    todogroup: "",
  }
  const [todoGroup, setTodoGroup] = useState(groupItem)
  const [newTodoItem, setNewTodo] = useState(tmpTodo)
  const [canAdd, setCanAdd] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [delTodoItem, setDelTodoItem] = useState({ arrId: -1, todo: tmpTodo })
  //   setTodoGroup(groupItem);

  useEffect(() => {
    const addTodoToGroup = async (todo: TodoItem) => {
      await addTodo(todo).then(newTodo => {
        // console.log("Received new item with id:" + newTodo.id);
        let tmpTodoGroup = todoGroup
        tmpTodoGroup.groupTodoList.push(newTodo)
        setTodoGroup(tmpTodoGroup)
      })
    }

    const delTodoFromGroup = async (arrId: number, todo: TodoItem) => {
      let tmpGroup: TodoGroupItem = todoGroup
      // console.log("Called Delete parent:" + todo.task);
      await deleteTodo(todo).then(retVal => {
        if (retVal) {
          // console.log("before:" + JSON.stringify(todoGroup, null, 2));
          tmpGroup.groupTodoList.splice(arrId, 1)
          setTodoGroup(tmpGroup)
          // console.log("after:" + JSON.stringify(todoGroup, null, 2));
        }
      })
    }

    if (canAdd) {
      // console.log("Sending new item to API");
      addTodoToGroup(newTodoItem).then(() => {
        setCanAdd(false)
      })
    }
    if (canDelete) {
      delTodoFromGroup(delTodoItem.arrId, delTodoItem.todo).then(() => {
        setCanDelete(false)
        setDelTodoItem({ arrId: -1, todo: tmpTodo })
      })
    }
  }, [canAdd, canDelete])

  const updateTodoItem = (arrId: number, todo: TodoItem) => {
    // console.log("change item inside group");
    let tmpGroup: TodoGroupItem = todoGroup
    tmpGroup.groupTodoList[arrId] = todo
    setTodoGroup(tmpGroup)
  }

  const deleteTodoItem = (arrId: number, todo: TodoItem) => {
    setDelTodoItem({ arrId, todo })
    setCanDelete(true)
  }

  return (
    <Box px="2">
      <Box d="flex">
        <Box w="90%">
          <Editable defaultValue={todoGroup.groupname}>
            <EditablePreview />
            <EditableInput />
          </Editable>
        </Box>
        <Box w="10%">
          <IconButton
            aria-label="add"
            icon="add"
            size="sm"
            onClick={() => {
              tmpTodo.task = "New " + todoGroup.groupname + " Task"
              tmpTodo.todogroup = todoGroup.groupname
              tmpTodo.todonum = todoGroup.groupname
              setNewTodo(tmpTodo)
              setCanAdd(true)
            }}
          />
        </Box>
      </Box>
      <Box>
        <Stack>
          {todoGroup.groupTodoList.map((ele, id, arr) => {
            return (
              <Todo
                todo={ele}
                todoKey={id}
                onChangeTodo={updateTodoItem}
                onDeleteTodo={deleteTodoItem}
                key={ele.id}
              />
            )
          })}
        </Stack>
      </Box>
    </Box>
  )
}
