import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/core";
// import { TodoItem } from "./Todo";
import { useState } from "react";
import { getTodos } from "../util/TodoAPI";
import { TodoGroup, TodoGroupItem } from "./TodoGroup";

export const TodoApp: React.FC = () => {
  // let todoArr: TodoItem[] = [];
  let groupArr: TodoGroupItem[] = [];

  // console.log("starting FC");
  // const [todolist, setTodoList] = useState(todoArr);
  const [groupList, setGroupList] = useState(groupArr);

  useEffect(() => {
    // console.log("inside useEffect");
    const fetchTodos = async () => {
      // console.log("inside fetchTodos");
      await getTodos().then((groupsarr) => {
        setGroupList(groupsarr);
      });
      // console.log("updating State");
    };
    fetchTodos();
  }, []);

  // console.log("object:" + JSON.stringify(todolist, null, 2));

  // const updateTodoItem = (arrId: number, todo: TodoItem) => {
  //   // console.log(
  //   //   "Parent update function:" +
  //   //     arrId +
  //   //     "; Todo Object:" +
  //   //     JSON.stringify(todo)
  //   // );

  //   let tempTodo = todolist;
  //   tempTodo[arrId].isComplete = todo.isComplete;
  //   setTodoList(tempTodo);
  //   // console.log("validating:" + JSON.stringify(todolist[arrId]));
  // };

  const updateGroupItem = (arrId: number, group: TodoGroupItem) => {
    console.log(
      "Parent Group Update:" +
        arrId +
        "; Group Object:" +
        JSON.stringify(group, null, 2)
    );
  };

  return (
    <Flex>
      <Box border="1px" w="20%">
        Rewards
      </Box>
      <Box d="flex" border="1px" p="6" w="80%">
        {groupList.map((gele, gid, garr) => {
          return (
            <TodoGroup
              groupItem={gele}
              groupKey={gid}
              key={gid}
              onChangeGroup={updateGroupItem}
            />
          );
        })}
        {/* <Box border="1px" w="25%">
          <Stack>
            {todolist.map((todo: TodoItem, todoArrId, tArr) => {
              return (
                <Todo
                  todo={todo}
                  todoKey={todoArrId}
                  onChangeTodo={updateTodoItem}
                  key={todo.id}
                />
              );
            })}
          </Stack>
        </Box>
        <Box border="1px" w="25%">
          <Stack></Stack>
        </Box> */}
      </Box>
    </Flex>
  );
};

// interface TodoListProps {}

// export const TodoList: React.FC<TodoListProps> = () => {
//   const API = "http://localhost:7071/api";
//   //   const API = "https://mslearn-tailwind-api.azurewebsites.net/api";
//   //   const KEY = "agwZ3ER1C3j666Zp0jstzH8S2QZ82avEbKAweyl3rzcCule/CcZmdw==";
//   const KEY = "";
//   let todolist = {};
//   // axios defaults
//   axios.defaults.headers.common["x-functions-key"] = KEY;

//   axios
//     .get(`${API}/todolist`)
//     .then((response) => {
//       todolist = response.data;
//     })
//     .catch((err) => {
//       console.log(err.message);
//     });
//   return <pre>{JSON.stringify(todolist, null, 2)}</pre>;
// };
