import React, { useEffect } from "react";
import { updateTodo } from "../util/TodoAPI";
import { Box, Checkbox, IconButton } from "@chakra-ui/core";
import { Editable, EditableInput, EditablePreview } from "@chakra-ui/core";
import { useState } from "react";

export interface TodoItem {
  id: string;
  todonum: string;
  task: string;
  isComplete: boolean;
  todogroup: string;
}

interface TodoProps {
  todo: TodoItem;
  todoKey: number;
  onChangeTodo: any;
  onDeleteTodo: any;
}

export const Todo: React.FC<TodoProps> = ({
  todo,
  todoKey,
  onChangeTodo,
  onDeleteTodo,
  ...props
}) => {
  const [todoState, setTodoState] = useState(todo.isComplete);
  const [taskValue, setTaskValue] = useState(todo.task);
  const [isDirty, setDirty] = useState(false);
  const [canUpdate, setUpdate] = useState(false);

  useEffect(() => {
    const updTodo = async () => {
      //   console.log("inside updTodo");
      todo.isComplete = todoState;
      todo.task = taskValue;
      await updateTodo(todo);
      //   console.log("finished updating todo");
    };
    if (canUpdate) {
      updTodo();
      setUpdate(false);
    }
  }, [canUpdate]);

  const setTodo = (isCom: boolean, val: string) => {
    todo.isComplete = isCom;
    todo.task = val;
    // console.log("Child func:" + JSON.stringify(todo));
    onChangeTodo(todoKey, todo);
  };

  return (
    <Box
      d="flex"
      borderWidth="1px"
      rounded="lg"
      w="20.0rem"
      alignItems="strech"
    >
      <Box d="flex">
        <Checkbox
          m="1"
          w="1.0rem"
          variantColor="red"
          isChecked={todoState}
          onChange={(e) => {
            setTodoState(e.target.checked);
            setTodo(e.target.checked, taskValue);
            setUpdate(true);
            // console.log("final val:" + JSON.stringify(todoI));
          }}
        ></Checkbox>
      </Box>
      <Box d="flex" w="80%">
        <Editable
          text-overflow="clip"
          w="100%"
          defaultValue={taskValue}
          onChange={(e) => {
            setTaskValue(e);
            setDirty(true);
          }}
          onSubmit={() => {
            if (isDirty) {
              setTodo(todoState, taskValue);
              setUpdate(true);
              setDirty(false);
            }
          }}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Box>
      <Box d="flex" verticalAlign="top">
        <IconButton
          my="2"
          aria-label="delete"
          icon="delete"
          size="sm"
          onClick={() => {
            onDeleteTodo(todoKey, todo);
          }}
        />
      </Box>
    </Box>
  );
};
